// extracted by mini-css-extract-plugin
export var active = "_404-styles-module--active--1b3e3";
export var arrow = "_404-styles-module--arrow--0293b";
export var arrowDisabled = "_404-styles-module--arrow--disabled--f3272";
export var arrowLeft = "_404-styles-module--arrow--left--5eddf";
export var arrowRight = "_404-styles-module--arrow--right--15bf4";
export var blink = "_404-styles-module--blink--9ea70";
export var bookingBtn = "_404-styles-module--booking-btn--7b58b";
export var bookingPageHeader = "_404-styles-module--booking-page-header--08cc3";
export var checkout = "_404-styles-module--checkout--4463c";
export var commentsSlider = "_404-styles-module--commentsSlider--5d3bb";
export var dot = "_404-styles-module--dot--2f93a";
export var dots = "_404-styles-module--dots--c9fb6";
export var equallyBound = "_404-styles-module--equallyBound--9ca40";
export var fader = "_404-styles-module--fader--5a359";
export var fader__slide = "_404-styles-module--fader__slide--255c9";
export var featuredSlider = "_404-styles-module--featuredSlider--6fc55";
export var globalWrapper = "_404-styles-module--global-wrapper--cd5c7";
export var headerIcons = "_404-styles-module--header-icons--3d781";
export var homepage = "_404-styles-module--homepage--a93be";
export var homepageReviews = "_404-styles-module--homepage-reviews--d49fe";
export var isOpenMenu = "_404-styles-module--is-open-menu--2ba16";
export var keenSlider = "_404-styles-module--keen-slider--a9e11";
export var lineMove = "_404-styles-module--lineMove--35d29";
export var logo = "_404-styles-module--logo--0abfd";
export var navigationWrapper = "_404-styles-module--navigation-wrapper--7ab0a";
export var noFound__box = "_404-styles-module--noFound__box--93f86";
export var noFound__container = "_404-styles-module--noFound__container--1b75a";
export var noFound__content = "_404-styles-module--noFound__content--8c563";
export var noFound__content__description = "_404-styles-module--noFound__content__description--8c2ed";
export var noFound__content__textButton = "_404-styles-module--noFound__content__textButton--73644";
export var noFound__content__title = "_404-styles-module--noFound__content__title--8d25a";
export var scrollNav = "_404-styles-module--scrollNav--eb8bd";
export var scrollNavWrap = "_404-styles-module--scrollNavWrap--11f2c";
export var slickSlide = "_404-styles-module--slick-slide--577a1";
export var startTxtArea = "_404-styles-module--startTxtArea--8a537";