import * as React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import * as styles from './404-styles.module.scss'
import { Button } from '../../components'
import imageNoFound from '../../assets/images/no_found/reflections-group-page-not-found.jpeg'
import Seo from '../../components/Seo'
// markup
const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.noFound__container}>
       <Seo title="Kamo - 404" />
      <div
        className={styles.noFound__box}
        style={{
          backgroundImage: `url(${imageNoFound})`,
        }}
      >
        <div className={styles.noFound__content}>
          <h2 className={styles.noFound__content__title}>{t('noFound.title')}</h2>
          <p className={styles.noFound__content__description}>{t('noFound.description')}</p>
          <Link to="/">
            <Button
              padding={{
                m: '0 25px',
                d: '0 25px',
              }}
              width={{
                m: '80%',
                d: '200px',
              }}>
              <span className={styles.noFound__content__textButton}>{t('noFound.button')}</span>
            </Button>
          </Link>
        </div>
      </div>
    </div>

  )
}

export default NotFoundPage
